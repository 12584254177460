import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { doc, collection, query, orderBy, onSnapshot, where, deleteDoc } from "firebase/firestore"
import { db } from '../firebase'

import UserContext from '../UserContext';
import { isAdmin } from '../helpers'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import PageHeader from '../components/PageHeader';
import PlanUserList from '../components/PlanUserList';
import PlanStatus from '../components/PlanStatus';

import CreatePlanUser from '../modals/CreatePlanUser';
import EditPlan from '../modals/EditPlan';

import { Pen, Trash } from 'react-bootstrap-icons'

function Plan() {
  let { id } = useParams();
  const userData = useContext(UserContext);

  const [showCreatePlanUser, setShowCreatePlanUser] = useState(false);
  const [showEditPlan, setShowEditPlan] = useState(false);
  const [plan, setPlan] = useState([])
  const [users, setUsers] = useState([])
  const [invitations, setInvitations] = useState([])
  const [adminUsers, setAdminUsers] = useState([])
  const [bundles, setBundles] = useState([])
  const [userAdmin, setUserAdmin] = useState(false);
  const [createUserDisabled, setCreateUserDisabled] = useState(true);

  const handleCloseCreatePlanUser = () => setShowCreatePlanUser(false);
  const handleShowCreatePlanUser = () => setShowCreatePlanUser(true);

  const handleCloseEditPlan = () => setShowEditPlan(false);
  const handleShowEditPlan = () => setShowEditPlan(true);

  const navigate = useNavigate();

  // Get Plan Data
  useEffect(() => {
    if (!userData) return

    setUserAdmin(isAdmin(userData))

    onSnapshot(doc(db, 'plans', id), (querySnapshot) => {
      if (!querySnapshot.exists()) {
        navigate("/dashboard");
      }

      setPlan({
        id: querySnapshot.id,
        data: querySnapshot.data(),
      });
    })
  }, [id, userData, navigate])

  useEffect(() => {
    if (!plan?.data || !userData) return
    
    if (plan?.data?.client !== userData.id && !userAdmin) {
      navigate("/dashboard");
    }
  }, [plan, userData, userAdmin, navigate])

  // Get User Data
  useEffect(() => {
    const q = query(collection(db, 'users'), where("plans", "array-contains", id))
    onSnapshot(q, (querySnapshot) => {
      setUsers(querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      })))
    })
  }, [id])

  // Get Invitation Data
  useEffect(() => {
    const q = query(collection(db, 'plans', id, 'invitations'), orderBy('created', 'desc'))
    onSnapshot(q, (querySnapshot) => {
      setInvitations(querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      })))
    })
  }, [id])

  // Get Bundle Data
  useEffect(() => {
    const q = query(collection(db, 'bundles'), orderBy('created', 'desc'))
    onSnapshot(q, (querySnapshot) => {
      setBundles(querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      })))
    })
  }, [])

  // Get Admin User Data
  useEffect(() => {
    const q = query(collection(db, 'admins'), orderBy('created', 'desc'), where('role', '==', 'client'))
    onSnapshot(q, (querySnapshot) => {
      setAdminUsers(querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      })))
    })
  }, [])

  useEffect(() => {
    if (plan?.data?.status !== 'active') return setCreateUserDisabled(true)
    
    const licenes = parseInt(plan?.data?.licences)
    const userCount = users?.length + invitations?.length
    setCreateUserDisabled(userCount >= licenes)
  }, [users, invitations, plan])

  const getUserName = (id) => {
    if (!id || !adminUsers || adminUsers.length < 1) return

    const filteredUsers = adminUsers.filter((user) => user.id === id)

    if (filteredUsers.length !== 1) return id
    return `${filteredUsers[0].data.name} (${filteredUsers[0].data.email})`
  }

  const deletePlan = async () => {
    if (users.length > 0 || invitations.length > 0) return alert("Please remove all users before deleting plan.")

    if (window.confirm(`Are you sure you want to delete '${plan.data.name}'?`)) {
      await deleteDoc(doc(db, 'plans', plan.id))
      navigate("/dashboard");
    }
  }

  return (
    <>
      <Container>
        <PageHeader title={plan?.data?.name || "..."} buttonTitle='Create User' buttonAction={handleShowCreatePlanUser} buttonDisabled={createUserDisabled} backTitle="Back" backLocation="/dashboard"></PageHeader>

        <Row>
          <Col lg={8} className='mb-3'>
            <PlanUserList planID={id} invitations={invitations} users={users}></PlanUserList>
          </Col>
          <Col lg={4} className='mb-3'>
            <Container className="p-4 bg-white rounded shadow-sm">
              <Row className='align-items-center mb-3'>
                <Col xs={8}>
                  <h2 className='fw-bold'>
                    Plan Details
                  </h2>
                </Col>
                {userAdmin && 
                  <Col xs={4} className='text-end'>
                    <div>
                      <Button variant='light' className='me-2 icon-button' onClick={handleShowEditPlan}>
                        <Pen />
                      </Button>
                      <Button variant="danger" className='icon-button' onClick={deletePlan}>
                        <Trash />
                      </Button>
                    </div>
                  </Col>
                }
              </Row>
              <Row>
                <Col>
                  <div className='mb-3'>
                    <span className='text-muted d-block'>Client</span>
                    <span className='d-block'>{getUserName(plan?.data?.client)}</span>
                  </div>
                  <div className='mb-3'>
                    <span className='text-muted d-block'>Status</span>
                    <PlanStatus className='d-block' planStatus={plan?.data?.status} />
                  </div>
                  <div className='mb-3'>
                    <span className='text-muted d-block'>Licences</span>
                    <span className='d-block'>{plan?.data?.licences}</span>
                  </div>
                  <div className='mb-3'>
                    <span className='text-muted d-block'>Bundle IDs</span>
                    <span className='d-block'>{plan?.data?.bundles.join(', ')}</span>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>

      <CreatePlanUser show={showCreatePlanUser} planID={id} handleClose={handleCloseCreatePlanUser} planUsers={users} planInvitations={invitations} planData={plan?.data} />
      <EditPlan show={showEditPlan} handleClose={handleCloseEditPlan} planID={id} planData={plan?.data} bundles={bundles} adminUsers={adminUsers} />
    </>
  )
}

export default Plan;