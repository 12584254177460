import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { auth, sendPasswordReset } from "../firebase";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import logo from '../assets/svg/bizversity-logo.svg';

function Reset() {
  const [email, setEmail] = useState("");
  const [user, loading] = useAuthState(auth);
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (user) navigate("/dashboard");
  }, [user, loading, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormLoading(true)
    setFormError(false)
    setFormSuccess(false)
    try {
      await sendPasswordReset(email)
      setFormSuccess(true)
    } catch {
      setFormError(true)
    } finally {
      setFormLoading(false)
    }
  }

  return (
    <>
      <section className="py-5">
        <div className="bg-light position-fixed top-0 end-0 start-0 bg-img-start" style={{ height: '32rem' }}>
          <div className="shape shape-bottom zi-1">
            <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
              <polygon fill="#e5e7eb" points="0,273 1921,273 1921,0 "></polygon>
            </svg>
          </div>
        </div>
        <Container className="py-5">
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={4} className='d-flex justify-content-center align-items-center flex-column'>
              <div className="w-50 pb-5">
                <img alt="Bizversity Logo" src={logo} className="w-100" />
                <span className="d-block w-100 text-center fw-bold pt-2">Admin</span>
              </div>
              <Card border="light" className="w-100 shadow-sm">
                <Card.Body>
                  {formError &&
                    <Alert variant='danger'>
                      <b>Error:</b> Couldn't find account with that email
                    </Alert>
                  }
                  {formSuccess &&
                    <Alert variant='success'>
                      A password reset email has been sent.
                    </Alert>
                  }
                  <Card.Title>
                    <h3 className="fw-bold">Forgot Password</h3>
                  </Card.Title>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formBundleName">
                      <Form.Label>Your Email</Form.Label>
                      <Form.Control required type="email" placeholder="hello@example.com" value={email} onChange={(e) => setEmail(e.target.value)} disabled={loading || formLoading} />
                    </Form.Group>
                    <div className="d-grid gap-2">
                      <Button variant="primary" type="submit" disabled={loading || formLoading}>
                        Send Reset Email
                      </Button>
                    </div>
                    <Link to="/" className="small mt-3 d-block text-end">Sign In</Link>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Reset;
