import React, { useState, useEffect } from 'react';

import { db, functions } from '../firebase'
import { collection, query, orderBy, onSnapshot, where, getDocs } from 'firebase/firestore'
import { httpsCallable } from "firebase/functions";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import UserList from '../components/UserList';
import PageHeader from '../components/PageHeader';

import CreateUser from '../modals/CreateUser';
import EditUser from '../modals/EditUser';

function Users() {
  const [loading, setLoading] = useState(true)
  const [showCreateUser, setShowCreateUser] = useState(false)
  const [showEditUser, setShowEditUser] = useState(false)
  const [users, setUsers] = useState([])
  const [userToEdit, setUserToEdit] = useState()

  const handleCloseCreateUser = () => setShowCreateUser(false);
  const handleShowCreateUser = () => setShowCreateUser(true);

  const handleCloseEditUser = () => setShowEditUser(false);
  const handleShowEditUser = () => setShowEditUser(true);

  useEffect(() => {
    const q = query(collection(db, 'admins'), orderBy('created', 'desc'))

    onSnapshot(q, (querySnapshot) => {
      setUsers(querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      })))

      setLoading(false)
    })
  }, [])

  const editUser = (user) => {
    setUserToEdit(user)
    handleShowEditUser()
  }

  const deleteUser = async (user) => {
    if (!window.confirm(`Are you sure you want to delete ${user?.data?.name} (${user?.data?.email})?`)) return

    // Check if user still has plans assigned to them
    const q = query(collection(db, 'plans'), orderBy('created', 'desc'), where('client', '==', user?.id))
    const docs = await getDocs(q)
    if (!docs.empty) return alert(`Cannot delete user as they have ${docs.size} plans assigned to them.`)

    // Send delete request
    const deleteUserAccount = httpsCallable(functions, 'deleteUserAccount');
    await deleteUserAccount({
      uid: user.id
    })
  }

  return (
    <>
      <Container>
        <PageHeader title='Users' buttonTitle='Create' buttonAction={handleShowCreateUser} buttonDisabled={loading}></PageHeader>
        <Row className='bg-white rounded shadow-sm'>
          <UserList users={users} editUser={editUser} deleteUser={deleteUser} loading={loading}></UserList>
        </Row>
      </Container>

      <CreateUser show={showCreateUser} handleClose={handleCloseCreateUser} users={users}></CreateUser>
      <EditUser show={showEditUser} handleClose={handleCloseEditUser} user={userToEdit}></EditUser>
    </>
  );
}

export default Users;
