import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot, doc, deleteDoc } from "firebase/firestore"
import { db } from '../firebase'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import BundleList from '../components/BundleList';
import PageHeader from '../components/PageHeader';

import CreateBundle from '../modals/CreateBundle';

function Bundles() {
  const [loading, setLoading] = useState(true)
  const [show, setShow] = useState(false)
  const [bundles, setBundles] = useState([])

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const q = query(collection(db, 'bundles'), orderBy('created', 'desc'))

    onSnapshot(q, (querySnapshot) => {
      setBundles(querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      })))

      setLoading(false)
    })
  }, [])

  const deleteBundle = async (bundle) => {
    if (window.confirm(`Are you sure you want to delete '${bundle.data.name}'?`)) {
      await deleteDoc(doc(db, 'bundles', bundle.id))
    }
  }

  return (
    <>
      <Container>
        <PageHeader title='Bundles' buttonTitle='Create' buttonAction={handleShow} buttonDisabled={loading}></PageHeader>
        <Row className='bg-white rounded shadow-sm'>
          <BundleList bundles={bundles} deleteBundle={deleteBundle} loading={loading}></BundleList>
        </Row>
      </Container>
      <CreateBundle show={show} handleClose={handleClose} bundles={bundles}></CreateBundle>
    </>
  );
}

export default Bundles;
