import React, { useState } from 'react';

import { db } from '../firebase'
import { collection, addDoc, Timestamp } from 'firebase/firestore'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

function CreateBundle({ show, handleClose, bundles }) {
  const [inputNameValue, setInputNameValue] = useState('')
  const [inputIDValue, setInputIDValue] = useState('')

  const [formLoading, setFormLoading] = useState(false)
  const [formError, setFormError] = useState(false)

  const onNameInput = ({target:{value}}) => setInputNameValue(value)
  const onIDInput = ({target:{value}}) => setInputIDValue(value)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormLoading(true)
    setFormError(false)
    
    try {
      const existingBundles = bundles.filter((bundle) => parseInt(bundle.data.uscreenBundleID) === parseInt(inputIDValue))

      if (existingBundles.length > 0) return setFormError(true)

      await addDoc(collection(db, 'bundles'), {
        name: inputNameValue,
        uscreenBundleID: inputIDValue,
        created: Timestamp.now(),
      })
      
      setInputNameValue('')
      setInputIDValue('')

      handleClose()
    } catch (err) {
      setFormError(true)
      console.error(err)
    } finally {
      setFormLoading(false)
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Bundle</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          {formError &&
            <Alert variant='danger'>
              Error adding bundle.
            </Alert>
          }
          <Form.Group className="mb-3" controlId="formBundleName">
            <Form.Label>Bundle Name</Form.Label>
            <Form.Control type="text" placeholder="Enter bundle name" onChange={onNameInput} value={inputNameValue} disabled={formLoading} required />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBundleID">
            <Form.Label>Bundle ID</Form.Label>
            <Form.Control type="number" placeholder="Enter bundle ID" onChange={onIDInput} value={inputIDValue} disabled={formLoading} required />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" disabled={formLoading}>
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default CreateBundle