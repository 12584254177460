import React, { useEffect, useState } from 'react';

import { db } from '../firebase'
import { setDoc, doc } from 'firebase/firestore'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

function EditPlan({ show, handleClose, bundles, adminUsers, planData, planID }) {
  const [inputNameValue, setInputNameValue] = useState('')
  const [inputLicencesValue, setInputLicencesValue] = useState('')
  const [inputClientValue, setInputClientValue] = useState('')
  const [inputBundlesValue, setInputBundlesValue] = useState([])
  const [inputStatusValue, setInputStatusValue] = useState(true)

  const [formLoading, setFormLoading] = useState(false)
  const [formError, setFormError] = useState(false)

  const onNameInput = ({target:{value}}) => setInputNameValue(value)
  const onLicencesInput = ({target:{value}}) => setInputLicencesValue(value)
  const onClientInput = ({target:{value}}) => setInputClientValue(value)
  const onStatusInput = () => setInputStatusValue(!inputStatusValue)

  const handleBundleCheckboxes = (bundle) => {
    const uscreenID = parseInt(bundle.data.uscreenBundleID);

    if (!inputBundlesValue.includes(uscreenID)) {
      setInputBundlesValue([...inputBundlesValue, uscreenID])
    } else {
      let newArr = inputBundlesValue.filter(item => item !== uscreenID);
      setInputBundlesValue([...newArr])
    }
  }

  useEffect(() => {
    setInputNameValue(planData?.name || '')
    setInputLicencesValue(planData?.licences || '')
    setInputClientValue(planData?.client || '')
    setInputBundlesValue(planData?.bundles || []);
    setInputStatusValue(planData?.status === 'active');
  }, [show, planData])

  const handleSubmit = async (e) => {
    e.preventDefault()

    setFormLoading(true)
    setFormError(false)
    
    try {
      await setDoc(doc(db, 'plans', planID), {
        name: inputNameValue,
        bundles: inputBundlesValue,
        licences: inputLicencesValue,
        client: inputClientValue,
        status: inputStatusValue ? 'active' : 'inactive'
      }, { merge: true })

      setInputNameValue('')
      setInputLicencesValue('')
      setInputBundlesValue([])
      setInputClientValue('')
      setInputStatusValue(true)

      handleClose()
    } catch (err) {
      setFormError(true)
      console.error(err)
    } finally {
      setFormLoading(false)
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Plan</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          {formError &&
            <Alert variant='danger'>
              Error editing plan.
            </Alert>
          }
          <Form.Group className="mb-3" controlId="formPlanName">
            <Form.Label>Plan Name</Form.Label>
            <Form.Control required type="text" placeholder="Enter plan name" onChange={onNameInput} value={inputNameValue} disabled={formLoading} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formLicencesName">
            <Form.Label>Licences</Form.Label>
            <Form.Control required type="number" placeholder="E.g. 10" onChange={onLicencesInput} value={inputLicencesValue} disabled={formLoading} max={1000} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBundles ">
            <Form.Label>Bundles</Form.Label>
            {bundles.map((bundle) => (
              <Form.Check type="checkbox" id={bundle.id} label={bundle.data.name} key={bundle.id} onChange={() => handleBundleCheckboxes(bundle)} disabled={formLoading} checked={inputBundlesValue.includes(parseInt(bundle.data.uscreenBundleID))} />
            ))}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formClient">
            <Form.Label>Client</Form.Label>
            <Form.Select required aria-label="Select client" onChange={onClientInput} value={inputClientValue} disabled={formLoading}>
              <option value='' disabled>Select client</option>
              {adminUsers.map((user) => (
                <option key={user.id} value={user.id}>{user.data.name} ({user.data.email})</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formStatus">
            <Form.Label>Status</Form.Label>
            <Form.Check type="switch" id="plan-status" label="Plan Active" onChange={onStatusInput} checked={inputStatusValue} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" disabled={formLoading}>
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default EditPlan;