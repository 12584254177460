import React, { useState, useEffect, useContext } from 'react';

import { db } from '../firebase'
import { collection, query, orderBy, onSnapshot, where } from 'firebase/firestore'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import PageHeader from '../components/PageHeader';
import PlanList from '../components/PlanList';

import CreatePlan from '../modals/CreatePlan';

import UserContext from '../UserContext';
import { isAdmin } from '../helpers'

function Dashboard() {
  const userData = useContext(UserContext);

  const [showCreatePlanModal, setShowCreatePlanModal] = useState(false);
  const [bundles, setBundles] = useState([])
  const [users, setUsers] = useState([])
  const [userAdmin, setUserAdmin] = useState(false);
  
  const handleClose = () => setShowCreatePlanModal(false);
  const handleShow = () => setShowCreatePlanModal(true);

  // Bundle Data
  useEffect(() => {
    const q = query(collection(db, 'bundles'), orderBy('created', 'desc'))
    onSnapshot(q, (querySnapshot) => {
      setBundles(querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      })))
    })
  }, [])

  // User Data
  useEffect(() => {
    const q = query(collection(db, 'admins'), orderBy('created', 'desc'), where('role', '==', 'client'))
    onSnapshot(q, (querySnapshot) => {
      setUsers(querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      })))
    })
  }, [])

  useEffect(() => {
    if (!userData) return

    setUserAdmin(isAdmin(userData))
  }, [userData]);

  return (
    <>
      <Container>
        <PageHeader title='Dashboard' buttonTitle={userAdmin ? 'Create' : null} buttonAction={userAdmin ? handleShow : null}></PageHeader>
        <Row className='bg-white rounded shadow-sm'>
          <PlanList></PlanList>
        </Row>
      </Container>

      <CreatePlan show={showCreatePlanModal} bundles={bundles} users={users} handleClose={handleClose}></CreatePlan>
    </>
  );
}

export default Dashboard;
