import React, { useContext, useEffect, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';

import logo from '../assets/svg/bizversity-logo.svg';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import UserContext from '../UserContext';
import { isAdmin } from '../helpers'

function Header({ logout }) {
  const { pathname } = useLocation();
  const userData = useContext(UserContext);
  const [userInitials, setUserInitials] = useState("");
  const [userAdmin, setUserAdmin] = useState(false);

  const UserMenu = (
    <div className='rounded-circle bg-light d-flex align-items-center justify-content-center' style={{ width: '40px', height: '40px' }}>
        <span className='uppercase'>{userInitials}</span>
    </div>
  )

  useEffect(() => {
    if (!userData) return

    if (userData?.name) {
      setUserInitials(userData?.name[0])
    }

    setUserAdmin(isAdmin(userData))
  }, [userData]);

  return (
    <>
      {pathname !== '/' && pathname !== '/reset' && 
        <Navbar bg="white" expand="lg" className="mb-5 shadow-sm py-3">
          <Container>
            <Navbar.Brand as={Link} to="/dashboard" className='d-flex align-items-center justify-content-center'>
              <img src={logo} alt="" width="125" className="d-inline-block align-text-top" />
              <span className='small ms-2'>Admin</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto align-items-center" activeKey={pathname}>
                <Nav.Link as={Link} eventKey="/dashboard" to="/dashboard">Dashboard</Nav.Link>
                {userAdmin &&
                  <>
                    <Nav.Link as={Link} eventKey="/bundles" to="/bundles">Bundles</Nav.Link>
                    <Nav.Link as={Link} eventKey="/users" to="/users">Users</Nav.Link>
                  </>
                }
                <NavDropdown title={UserMenu} id="profile-nav-dropdown">
                  <NavDropdown.ItemText>
                    <span className="d-block">Signed in as </span>
                    <span>{userData?.email}</span>
                  </NavDropdown.ItemText>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={logout}>
                    Sign out
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      }
    </>
  );
}

export default Header;