import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

import { Trash } from 'react-bootstrap-icons'

function BundleList({ bundles, deleteBundle, loading }) {
  return (
    <Container className="p-4">
      <Row>
        <Col>
          <Table responsive className='large-table'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Uscreen ID</th>
                <th>Date Created</th>
                <th className='text-end'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {!loading && bundles.map((bundle) => (
                <tr key={bundle.id}>
                  <td className='align-middle'>{bundle.data.name}</td>
                  <td className='align-middle'>{bundle.data.uscreenBundleID}</td>
                  <td className='align-middle'>{new Date(bundle.data.created.seconds*1000).toDateString()}</td>
                  <td className='text-end align-middle'>
                    <Button variant='danger' className='icon-button' onClick={() => deleteBundle(bundle)}>
                      <Trash />
                    </Button>
                  </td>
                </tr>
              ))}
              {loading && (
                <tr>
                  <td className='align-middle'>Loading bundles...</td>
                  <td className='align-middle'></td>
                  <td className='align-middle'></td>
                  <td className='align-middle'></td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}

export default BundleList;