import Badge from 'react-bootstrap/Badge';

const RoleBadge = ({ role }) => {
    const admin = role === 'admin'

    return (
      <Badge bg={admin ? 'success' : 'warning'}>{admin ? 'Admin' : 'Client'}</Badge>
    )
}

export default RoleBadge