import React, { useState } from 'react';

import { arrayRemove, doc, updateDoc } from "firebase/firestore"
import { httpsCallable } from "firebase/functions";
import { db, functions } from '../firebase'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';

import { ArrowClockwise, Trash } from 'react-bootstrap-icons'

function PlanUserList({ planID, invitations, users }) {

  const deletePlanUser = async (user) => {
    if (window.confirm(`Are you sure you want to '${user.data.name}'?`)) {
      await updateDoc(doc(db, 'users', user.id), {
        plans: arrayRemove(planID)
      })
    }
  }

  const TableRow = ({ user, status }) => {
    const [refreshLoading, setRefreshLoading] = useState(false);

    const refreshPlanUser = async (user) => {
      setRefreshLoading(true)

      const refreshUserBundleAccess = httpsCallable(functions, 'refreshUserBundleAccess');

      try {
        await refreshUserBundleAccess({ userID: user.id })
      } catch (e) {
        console.error(e);
      } finally {
        setRefreshLoading(false)
      }
    }

    return (
      <tr>
        <td className='align-middle'>{user.data.name}</td>
        <td className='align-middle'>{user.data.email}</td>
        <td className='align-middle'>{status}</td>
        <td className='text-end align-middle'>
          <Button variant='light' className='me-2 icon-button' onClick={() => refreshPlanUser(user)} disabled={refreshLoading}>
            <ArrowClockwise />
          </Button>
          <Button variant='danger' className='icon-button' onClick={() => deletePlanUser(user)}>
            <Trash />
          </Button>
        </td>
      </tr>
    )
  }

  const PendingBadge = () => {
    return (
      <Badge bg='warning'>Pending</Badge>
    )
  }

  const ActiveBadge = () => {
    return (
      <Badge bg='success'>Active</Badge>
    )
  }

  return (
    <Container className="p-4 p-4 bg-white rounded shadow-sm">
      <Row className='align-items-center'>
        <Col>
          <h2 className='fw-bold'>
            Users
          </h2>
        </Col>
        <Col className='text-end'>
          <span className='text-muted'>{invitations?.length + users?.length} users</span>
        </Col>
      </Row>
      <Row>
        <Col>
        <Table responsive className='large-table'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Status</th>
            <th className='text-end'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {invitations.map((user) => (
            <TableRow key={user.id} user={user} status={<PendingBadge />} />
          ))}
          {users.map((user) => (
            <TableRow key={user.id} user={user} status={<ActiveBadge />} />
          ))}
          {users?.length < 1 && invitations?.length < 1 && (
            <tr>
              <td className='align-middle'>No users added to plan.</td>
              <td className='align-middle'></td>
              <td className='align-middle'></td>
              <td className='align-middle'></td>
            </tr>
          )}
        </tbody>
      </Table>
        </Col>
      </Row>
    </Container>
  );
}

export default PlanUserList;