import React, { useState } from 'react';

import { db } from '../firebase'
import { collection, addDoc, Timestamp } from 'firebase/firestore'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

function CreatePlan({ show, handleClose, bundles, users }) {
  const [inputNameValue, setInputNameValue] = useState('')
  const [inputLicencesValue, setInputLicencesValue] = useState('')
  const [inputClientValue, setInputClientValue] = useState('')
  const [inputBundlesValue, setInputBundlesValue] = useState([])

  const [formLoading, setFormLoading] = useState(false)
  const [formError, setFormError] = useState(false)

  const onNameInput = ({target:{value}}) => setInputNameValue(value)
  const onLicencesInput = ({target:{value}}) => setInputLicencesValue(value)
  const onClientInput = ({target:{value}}) => setInputClientValue(value)

  const handleBundleCheckboxes = (e) => {
    const uscreenID = parseInt(e.target.dataset.uscreenid);

    if (e.target.checked) {
      if (!inputBundlesValue.includes(uscreenID)) {
        setInputBundlesValue([...inputBundlesValue, uscreenID])
      }
    } else {
      let newArr = inputBundlesValue.filter(item => item !== uscreenID);
      setInputBundlesValue([...newArr])
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormLoading(true)
    setFormError(false)
    
    try {
      await addDoc(collection(db, 'plans'), {
        name: inputNameValue,
        bundles: inputBundlesValue,
        licences: inputLicencesValue,
        created: Timestamp.now(),
        client: inputClientValue,
        status: 'active'
      })

      setInputNameValue('')
      setInputLicencesValue('')
      setInputBundlesValue([])
      setInputClientValue('')

      handleClose()
    } catch (err) {
      setFormError(true)
      console.error(err)
    } finally {
      setFormLoading(false)
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create Plan</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          {formError &&
            <Alert variant='danger'>
              Error creating plan.
            </Alert>
          }
          <Form.Group className="mb-3" controlId="formPlanName">
            <Form.Label>Plan Name</Form.Label>
            <Form.Control required type="text" placeholder="Enter plan name" onChange={onNameInput} value={inputNameValue} disabled={formLoading} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formLicencesName">
            <Form.Label>Licences</Form.Label>
            <Form.Control required type="number" placeholder="E.g. 10" onChange={onLicencesInput} value={inputLicencesValue} disabled={formLoading} max={1000} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBundles ">
            <Form.Label>Bundles</Form.Label>
            {bundles.map((bundle) => (
              <Form.Check type="checkbox" data-uscreenid={bundle.data.uscreenBundleID} id={bundle.id} label={bundle.data.name} key={bundle.id} onChange={handleBundleCheckboxes} disabled={formLoading} />
            ))}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formClient">
            <Form.Label>Client</Form.Label>
            <Form.Select required aria-label="Select client" onChange={onClientInput} value={inputClientValue} disabled={formLoading}>
              <option value='' disabled>Select client</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>{user.data.name} ({user.data.email})</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" disabled={formLoading}>
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default CreatePlan;