import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

import { Pen, Trash } from 'react-bootstrap-icons'

import RoleBadge from './RoleBadge';

function UserList({ users, deleteUser, editUser, loading }) {
  return (
    <Container className="p-4">
      <Row>
        <Col>
          <Table responsive className='large-table'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Date Created</th>
                <th>Role</th>
                <th className='text-end'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {!loading && users.map((user) => (
                <tr key={user.id}>
                  <td className='align-middle'>{user.data.name}</td>
                  <td className='align-middle'>{user.data.email}</td>
                  <td className='align-middle'>{new Date(user.data.created.seconds*1000).toDateString()}</td>
                  <td className='align-middle'>
                    <RoleBadge role={user.data.role} />
                  </td>
                  <td className='text-end align-middle'>
                    <Button variant='light' className='me-2 icon-button' onClick={() => editUser(user)}>
                      <Pen />
                    </Button>
                    <Button variant='danger' className='icon-button' onClick={() => deleteUser(user)}>
                      <Trash />
                    </Button>
                  </td>
                </tr>
              ))}
              {loading && (
                <tr>
                  <td className='align-middle'>Loading users...</td>
                  <td className='align-middle'></td>
                  <td className='align-middle'></td>
                  <td className='align-middle'></td>
                  <td className='align-middle'></td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}

export default UserList;