import React, { useState, useEffect } from 'react';

import { functions } from '../firebase'
import { httpsCallable } from "firebase/functions";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

function EditUser({ show, handleClose, user }) {
  const [inputNameValue, setInputNameValue] = useState('')
  const [inputEmailValue, setInputEmailValue] = useState('')
  const [inputRoleValue, setInputRoleValue] = useState('')

  const onNameInput = ({ target: { value } }) => setInputNameValue(value)
  const onEmailInput = ({ target: { value } }) => setInputEmailValue(value)
  const onRoleInput = ({ target: { value } }) => setInputRoleValue(value)

  const [formLoading, setFormLoading] = useState(false)
  const [formError, setFormError] = useState(false)

  useEffect(() => {
    setInputNameValue(user?.data?.name || '')
    setInputEmailValue(user?.data?.email || '')
    setInputRoleValue(user?.data?.role || '')
  }, [show, user])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormLoading(true)
    setFormError(false)

    try {
      // Send update request
      const updateUserAccount = httpsCallable(functions, 'updateUserAccount');
      await updateUserAccount({
        uid: user.id,
        email: inputEmailValue,
        name: inputNameValue,
        role: inputRoleValue,
      })

      // Clear form values
      setInputNameValue('')
      setInputEmailValue('')
      setInputRoleValue('')

      // Close form
      handleClose()
    } catch (err) {
      setFormError(true)
      console.error(err)
    } finally {
      setFormLoading(false)
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit User</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          {formError &&
            <Alert variant='danger'>
              <b>Error:</b> Error editing user.
            </Alert>
          }
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control required type="text" placeholder="Enter user name" onChange={onNameInput} value={inputNameValue} disabled={formLoading} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control required type="email" placeholder="Enter user email" onChange={onEmailInput} value={inputEmailValue} disabled={formLoading} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formRole">
            <Form.Label>Role</Form.Label>
            <Form.Select required aria-label="Select user role" onChange={onRoleInput} value={inputRoleValue} disabled={formLoading}>
              <option value='' disabled>Select role</option>
              <option value="admin">Admin</option>
              <option value="client">Client</option>
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" disabled={formLoading}>
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default EditUser