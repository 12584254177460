import React, { useState } from 'react';

import { httpsCallable } from "firebase/functions";
import { functions } from '../firebase'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

function CreateUser({ show, handleClose, users }) {
  const [inputNameValue, setInputNameValue] = useState('')
  const [inputEmailValue, setInputEmailValue] = useState('')
  const [inputPasswordValue, setInputPasswordValue] = useState('')
  const [inputRoleValue, setInputRoleValue] = useState('')

  const onNameInput = ({target:{value}}) => setInputNameValue(value)
  const onEmailInput = ({target:{value}}) => setInputEmailValue(value)
  const onPasswordInput = ({target:{value}}) => setInputPasswordValue(value)
  const onRoleInput = ({target:{value}}) => setInputRoleValue(value)

  const [formLoading, setFormLoading] = useState(false)
  const [formError, setFormError] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormLoading(true)
    setFormError(false)

    try {
      const existingUsers = users.filter((user) => user.data.email === inputEmailValue)

      if (existingUsers.length > 0) return setFormError(true)

      const createUserAccount = httpsCallable(functions, 'createUserAccount');

      await createUserAccount({
        email: inputEmailValue,
        password: inputPasswordValue,
        name: inputNameValue,
        role: inputRoleValue,
      })
      
      // Clear form values
      setInputNameValue('')
      setInputEmailValue('')
      setInputPasswordValue('')
      setInputRoleValue('')

      // Close form
      handleClose()
    } catch (err) {
      setFormError(true)
      console.error(err)
    } finally {
      setFormLoading(false)
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create User</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          {formError &&
            <Alert variant='danger'>
              <b>Error:</b> Error creating user.
            </Alert>
          }
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control required type="text" placeholder="Enter user name" onChange={onNameInput} value={inputNameValue} disabled={formLoading} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control required type="email" placeholder="Enter user email" onChange={onEmailInput} value={inputEmailValue} disabled={formLoading} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control required type="password" placeholder="Enter user password" onChange={onPasswordInput} value={inputPasswordValue} disabled={formLoading} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formRole">
            <Form.Label>Role</Form.Label>
            <Form.Select required aria-label="Select user role" onChange={onRoleInput} value={inputRoleValue} disabled={formLoading}>
              <option value='' disabled>Select role</option>
              <option value="admin">Admin</option>
              <option value="client">Client</option>
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" disabled={formLoading}>
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default CreateUser