import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Footer = () => {
  return (
    <Container className='py-5 my-5'>
      <Row>
        <Col className='text-muted small text-center'>
          <span>{new Date().getFullYear()} &copy; <a href="https://bizversity.com">Bizversity</a></span>
          <span> | </span>
          <a href="https://watch.bizversity.com">Watch</a>
          <span> | </span>
          <a href="https://support.bizversity.com">Support</a>
        </Col>
      </Row>
    </Container>
  )
}

export default Footer