import Badge from 'react-bootstrap/Badge';

const PlanStatus = ({ planStatus }) => {
    const active = planStatus === 'active'

    return (
      <Badge bg={active ? 'success' : 'warning'}>{active ? 'Active' : 'Inactive'}</Badge>
    )
}

export default PlanStatus