import { Link } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { Plus, ArrowLeft } from 'react-bootstrap-icons'

function PageHeader({ title, buttonTitle, buttonAction, buttonDisabled, backTitle, backLocation }) {
  return (
    <>
      <Row className='align-items-center mb-4'>
        <Col>
          {backTitle && backLocation && 
            <Button as={Link} to={backLocation} className="icon-button" variant="white" size="sm">
              <ArrowLeft className='me-1' />
              {backTitle}
            </Button>
          }
          <h1 className="fw-bold">{title}</h1>
        </Col>
        {buttonTitle && buttonAction &&
          <Col className="text-end">
            <Button variant="primary" onClick={buttonAction} disabled={buttonDisabled} className="icon-button">
              <Plus width={24} height={24} />
              <span>{buttonTitle}</span>
            </Button>
          </Col>
        }
      </Row>
    </>
  )
}

export default PageHeader;