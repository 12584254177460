import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";

import { auth, db, logout } from './firebase'
import { getDoc, doc } from 'firebase/firestore'

import Header from './components/Header';
import Footer from './components/Footer';
import Login from './pages/Login';
import Reset from './pages/Reset';
import Dashboard from './pages/Dashboard';
import Bundles from './pages/Bundles';
import Plan from './pages/Plan';
import Users from './pages/Users';

import './App.scss';

import UserContext from './UserContext';
import { isAdmin } from './helpers'

function Root() {
  const [user, loading] = useAuthState(auth);
  const [userData, setUserData] = useState("");
  // const [userAdmin, setUserAdmin] = useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  
  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const userDoc = await getDoc(doc(db, 'admins', user?.uid))
        
        setUserData({
          id: userDoc.id,
          ...userDoc.data()
        });
      } catch (err) {
        console.error(err);
      }
    };
    
    if (loading || !user) return;

    fetchUserName();
  }, [user, loading]);
  
  useEffect(() => {
    if (loading) return;

    if (!user) {
      if (pathname !== '/reset') {
        return navigate("/")
      } else {
        return;
      }
    }

    if (userData && !isAdmin(userData)) {
      // Use is not an admin
      if (pathname === '/bundles' || pathname === '/users') {
        return navigate('/')
      }
    }
  }, [user, loading, navigate, pathname, userData]);

  return (
    <UserContext.Provider value={userData}>
      <Header logout={logout}></Header>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/reset" element={<Reset />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/bundles" element={<Bundles />} />
        <Route path="/users" element={<Users />} />
        <Route path="/plan/:id" element={<Plan />} />
      </Routes>
      <Footer></Footer>
    </UserContext.Provider>
  )
}

function App() {
  return (
    <>
      <BrowserRouter>
        <Root></Root>
      </BrowserRouter>
    </>
  );
}

export default App;
