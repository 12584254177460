import React, { useState, useEffect, useContext } from 'react';
import { collection, query, orderBy, onSnapshot, where } from "firebase/firestore"
import { db } from '../firebase'
import { useNavigate } from "react-router-dom";

import UserContext from '../UserContext';
import { isAdmin } from '../helpers'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

import { ArrowRight } from 'react-bootstrap-icons'

import PlanStatus from './PlanStatus';

function PlanList() {
  const userData = useContext(UserContext);

  const [loading, setLoading] = useState(true)
  const [plans, setPlans] = useState([])
  const [userAdmin, setUserAdmin] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!userData) return

    setUserAdmin(isAdmin(userData))

    let q

    if (isAdmin(userData)) {
      q = query(collection(db, 'plans'), orderBy('created', 'desc'))
    } else {
      q = query(collection(db, 'plans'), orderBy('created', 'desc'), where('client', '==', userData?.id))
    }

    onSnapshot(q, (querySnapshot) => {
      setPlans(querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      })))
      setLoading(false)
    })
  }, [userData, userAdmin])

  const navigateToPlan = (planID) => {
    navigate(`/plan/${planID}`);
  }

  return (
    <Container className="p-4">
      <Row>
        <Col>
          <h2 className='fw-bold'>Plans</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table responsive className='large-table'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Licences</th>
                <th>Date Created</th>
                <th>Status</th>
                <th className='text-end'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {!loading && plans.map((plan) => (
                <tr key={plan.id}>
                  <td className='align-middle'>
                    <span className='cursor-pointer' onClick={() => navigateToPlan(plan.id)}>
                      {plan.data.name}
                    </span>
                  </td>
                  <td className='align-middle text-muted'>{plan.data.licences}</td>
                  <td className='align-middle text-muted'>{new Date(plan.data.created.seconds * 1000).toDateString()}</td>
                  <td className='align-middle text-muted'>
                    <PlanStatus planStatus={plan.data.status}></PlanStatus>
                  </td>
                  <td className='text-end align-middle'>
                    <Button variant='light' onClick={() => navigateToPlan(plan.id)} className="icon-button">
                      Manage
                      <ArrowRight className='ms-1' />
                    </Button>
                  </td>
                </tr>
              ))}
              {!loading && plans?.length < 1 && (
                <tr>
                  <td className='align-middle'>Sorry, we couldn't find any plans.</td>
                  <td className='align-middle'></td>
                  <td className='align-middle'></td>
                  <td className='align-middle'></td>
                  <td className='text-end align-middle'>
                  </td>
                </tr>
              )}
              {loading && (
                <tr>
                  <td className='align-middle'>Loading plans...</td>
                  <td className='align-middle'></td>
                  <td className='align-middle'></td>
                  <td className='align-middle'></td>
                  <td className='text-end align-middle'>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}

export default PlanList;