import React, { useState } from 'react';

import { db } from '../firebase'
import { collection, addDoc, Timestamp } from 'firebase/firestore'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

function CreatePlanUser({ show, planID, handleClose, planUsers, planInvitations, planData }) {
  const [inputNameValue, setInputNameValue] = useState('')
  const [inputEmailValue, setInputEmailValue] = useState('')

  const [formLoading, setFormLoading] = useState(false)
  const [formError, setFormError] = useState(false)

  const onNameInput = ({target:{value}}) => setInputNameValue(value)
  const onEmailInput = ({target:{value}}) => setInputEmailValue(value)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormLoading(true)
    setFormError(false)
    
    try {
      const planUserEmails = planUsers.map((user) => {return user.data.email})
      const planInvitationEmails = planInvitations.map((user) => {return user.data.email})
      const existingEmails = [...planUserEmails, ...planInvitationEmails];
      const licenes = parseInt(planData?.licences)
      const userCount = planUsers?.length + planInvitations?.length

      if (planData?.status !== 'active') {
        return setFormError(true)
      }

      if (userCount >= licenes) {
        return setFormError(true)
      }

      if (existingEmails.includes(inputEmailValue)) {
        return setFormError(true)
      }

      await addDoc(collection(db, 'plans', planID, 'invitations'), {
        name: inputNameValue,
        email: inputEmailValue,
        created: Timestamp.now(),
      })

      setInputNameValue('')
      setInputEmailValue('')

      handleClose()
    } catch (err) {
      setFormError(true)
      console.error(err)
    } finally {
      setFormLoading(false)
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create User</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          {formError &&
            <Alert variant='danger'>
              Error adding new user.
            </Alert>
          }
          <Form.Group className="mb-3" controlId="formUserName">
            <Form.Label>User Name</Form.Label>
            <Form.Control required type="text" placeholder="Enter user name" onChange={onNameInput} value={inputNameValue} disabled={formLoading} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formUserEmail">
            <Form.Label>User Email</Form.Label>
            <Form.Control required type="email" placeholder="Enter user email" onChange={onEmailInput} value={inputEmailValue} disabled={formLoading} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" disabled={formLoading}>
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default CreatePlanUser;